import styles from './navbar.module.scss'
import { ReactComponent as LogoSVG } from '../../img/logo.svg'

export default function Navbar() {
    return (
        <div className={`${styles.center} ${styles.column}`}>
            <div className={`${styles.center}`}>
                <div className={`${styles.nav__photo}`}>
                    <LogoSVG />
                </div>
                <div className={`${styles.cart}`}>
                    CART (0)
                </div>

            </div>
            {/* <div className={`${styles.center} ${styles.row} ${styles.gap}`}>
                <p>Store</p>
                <p>Our Story</p>
                <p>Contact</p>
            </div> */}
        </div>
    )
}