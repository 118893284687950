import './App.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Root from './routes/root';
import Landing from './routes/Landing/landing';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: [
        { index: true, element: <Landing /> },
        // { path: '/catalog/:type', element: <Catalog /> },
        // { path: '/cart', element: <Cart /> },
        // { path: '/checkout', element: <Checkout /> },
        // { path: '/item/:id', loader: modificationLoader, element: <Modification /> },
      ]
    }
  ]);

  return (
    <div className='eggshell'>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
