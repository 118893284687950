import styles from './landing.module.scss'

export default function Landing() {
    return (
        <div className={styles.landing}>
            <div className={styles.text}>
                <p>We are currently building the website.</p>
                <p>If you have questions, comments, or want to chat: <b>turner@pastitos.com</b></p>
                
            </div>
        </div>
    )
}