import styles from './footer.module.scss'

export default function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.center}>
                <a href="//instagram.com/eatpastitos" className={styles.info}>Instagram</a>
            </div>
        </div>  
    )
}